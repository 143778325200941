export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    // featuresHover();
  },
};

// function featuresHover() {
//   $('.home-features-section .features-item').each(function () {
//     var $img = $(this).find('.image-wrp.desktop');
//     $img.css('opacity', '0');

//     $(this).mousemove(function(e) {
//       $img.stop(1, 1).css('opacity', '1');
//       $img.offset({
//           top: e.pageY - ($img.outerHeight()/2),
//           left: e.pageX - ($img.outerWidth()/2),
//       });
//     }).mouseleave(function() {
//         // $img.fadeOut();
//         $img.css('opacity', '0');
//     });
//   });
// }
